#sidemenu .sidelist {
    flex-grow: 1;
    flex-basis: 20%;
    display: flex;
    border: 1px solid #888;
    border-radius: 6px;
    margin: 2px;
    background-color: #666;
    height: 0;
}

.sidelist .innercontainer {
    flex-grow: 1;
    border: 1px solid #666;
    border-radius: 6px;
    margin: 4px;
    background-color: #333;
    padding: 4px;
    display: flex;
    flex-direction: column;
}

.sidelist .innercontainer .listheader {
    border-bottom: 1px solid #888;
    font-weight: bold;
}

.sidelist .innercontainer .listheader {
    cursor: pointer;
}

.sidelist .innercontainer .listheader:hover:not(:has(.listadd:hover)) {
    background-color: #555;
}

.sidelist .innercontainer .listheader span.icon {
    display: none;
}

.sidelist .innercontainer .listheader:hover:not(:has(.listadd:hover)) span.icon {
    display: inline;
    font-size: 80%;
    margin-left: 0.5em;
}

.sidelist.quests .innercontainer .listheader {
    border-color: #284;
}

.sidelist.peopleandorgs .innercontainer .listheader {
    border-color: #d92;
}

.sidelist.places .innercontainer .listheader {
    border-color: #b54;
}

.sidelist.misc .innercontainer .listheader {
    border-color: #58a;
}

.sidelist .innercontainer .itemcontainer {
    flex-grow: 1;
    overflow-y: auto;
    margin-top: 2px;
}

.sidelist .innercontainer ul {
    margin: 0px;
    padding: 0px;
}

.sidelist .innercontainer ul.children {
    padding-left: 0.5em;
    border-left: 1px dotted #666;
    margin-bottom: 0.1em;
}

.sidelist .innercontainer ul li {
    list-style-type: none;
    cursor: pointer;
    padding-left: 0.25em;
    text-indent: -0.25em;
}

.sidelist .innercontainer ul li.archived {
    color: #888;
}

.sidelist .innercontainer ul li:hover {
    background-color: #555;
}

.sidelist .innercontainer ul span.icon {
    font-size: 70%;
    margin-right: 0.2em;
    vertical-align: middle;
}

.sidelist .innercontainer ul span.expand, .sidelist .innercontainer ul span.collapse {
    float: right;
    padding-right: 0.2em;
    color: #888;
}

.sidelist .innercontainer ul span.collapse {
    color: #555;
}

.sidelist .innercontainer ul li:hover span.collapse {
    color: #888;
}

.sidelist .innercontainer ul span.expand:hover, .sidelist .innercontainer ul span.collapse:hover {
    color: #ccc !important;
}

.sidelist .listadd {
    float: right;
    margin-top: -2px;
    cursor: pointer;
}

.sidelist .listadd:hover {
    background-color: #888;
}
