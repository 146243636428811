.searchbackdrop {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    text-align: center;
    background-color: rgba(30, 30, 30, 0.75);
    display: flex;
    justify-content: center;
    padding: 5vh;
    box-sizing: border-box;
    align-items: flex-start;
    z-index: 11000;
}

.searchbackdrop .search {
    min-width: 200px;
    max-width: 500px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    background-color: #444;
    padding: 8px;
    border-radius: 6px;
}

.search .searchbox {
    display: flex;
}

.search .searchbox input {
    font-size: 24px;
    flex-grow: 1;
    background-color: #222;
    border: none;
    border-bottom: 1px solid #888;
}

.search .searchresults {
    overflow-y: auto;
    background-color: #333;
}

.search .searchresults .searchitem {
    font-size: 120%;
    padding: 4px;
    cursor: pointer;
    border-bottom: 1px solid #444;
}

.search .searchresults .searchitem:hover {
    background-color: #555;
}

.search .searchresults .searchitem.quest {
    border-left: 4px solid #284;
    border-right: 4px solid #284;
}

.search .searchresults .searchitem.personororg {
    border-left: 4px solid #d92;
    border-right: 4px solid #d92;
}

.search .searchresults .searchitem.place {
    border-left: 4px solid #b54;
    border-right: 4px solid #b54;
}

.search .searchresults .searchitem.miscitem {
    border-left: 4px solid #58a;
    border-right: 4px solid #58a;
}

.search .searchresults .searchitem.logentry {
    border-left: 4px solid #ccc;
    border-right: 4px solid #ccc;
}

.search .searchresults .resultexcerpt {
    font-style: italic;
    color: #888;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}