#adventurepage {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 15px);
}

#adventurepage #sidemenu {
    width: 250px;
    min-width: 250px; /* Why the hell do I need this? */
    flex-direction: column;
    display: flex;
}

#adventurepage #mainarea {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    overflow-y: auto;
    background-image: url('images/background.png');
    background-size: cover;
    background-repeat: no-repeat;
}

#adventurepage #mainarea #topbar {
    width: 100%;
    padding: 8px;
    font-size: 20px;
    font-weight: bold;
    color: #ccc;
    background-color: #282828;
    display: flex;
    position: sticky;
    top: 0px;
    z-index: 1;
}

#adventurepage #topbar #adventurename {
    flex-grow: 1;
}

#topbar #toolbar {
    display: flex;
    gap: 8px;
}

#topbar .toolbarbutton:hover {
    background-color: #555;
    cursor: pointer;
}