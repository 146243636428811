.itembox .links {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.itembox .links {
    row-gap: 4px;
}

.itembox ul {
    margin: 0px;
    padding: 0px;
}

.itembox .links ul li {
    list-style-type: none;
    cursor: pointer;
    padding-left: 0.5em;
    text-indent: -0.25em;
    color: #eee;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-thickness: from-font;
}

.itembox .links ul li .removeLink, .itembox .links ul li .pinLink {
    font-size: 9pt;
    margin-right: 0.5em;
    color: #666;
    filter: drop-shadow(0px 0px 1px black);
}

.itembox .links ul li .removeLink:hover {
    color: #c66;
    filter: drop-shadow(0px 0px 1px red);
}

.itembox .links ul li .pinLink:hover {
    color: #6c6;
    filter: drop-shadow(0px 0px 1px green);
}

.itembox ul li.quest {
    border-left: 4px solid #284;
}

.itembox ul li.personororg {
    border-left: 4px solid #d92;
}

.itembox ul li.place {
    border-left: 4px solid #b54;
}

.itembox ul li.miscitem {
    border-left: 4px solid #58a;
}

.itembox ul li.logentry {
    border-left: 4px solid #ccc;
}

.itembox .linkcategory {
    color: #ccc;
}

.itembox select.linkselect {
    color: #ddd;
    flex-grow: 1;
    width: 0;
}

.itembox select.linkselect option {
    background-color: #333;
}

.itembox select.linkselect option.quest {
    background-color: #142;
}

.itembox select.linkselect option.personororg {
    background-color: #684810;
}

.itembox select.linkselect option.place {
    background-color: #582820;
}

.itembox select.linkselect option.miscitem {
    background-color: #284050;
}

.itembox select.linkselect option.logentry {
    background-color: #666;
}