.newadventurecontainer {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
  font-size: 24px;
  height: 100vh;
}

.newadventurecontainer .spacer {
  flex-grow: 1;
}

.newadventurecontainer .newadventureform {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.newadventurecontainer .newadventureinputcolumn {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.newadventurecontainer .newadventureinputrow {
  display: flex;
  flex-direction: row;
}

.newadventurecontainer .newadventurebuttoncolumn {
  display: flex;
  height: 100%;
}

.newadventurecontainer span {
  flex-grow: 0;
  flex-shrink: 0;
}

.newadventurecontainer input {
  font-size: 24px;
  margin-left: 0.5em;
  flex-grow: 1;
  height: 26px;
  border: none;
  border-bottom: 1px solid #888;
  width: 100%;
}

.newadventurecontainer select {
  font-size: 20px;
  color: #888;
  margin-left: 0.5em;
  flex-grow: 1;
  border: none;
  border-bottom: 1px dashed #888;
  cursor: pointer;
}

.newadventurecontainer button {
  font-size: 24px;
  border: 1px solid #ccc;
  margin-left: 0.5em;
  flex-grow: 0;
}

.newadventurereminder {
  text-align: center;
  font-size: 20px;
  font-style: italic;
  color: #888;
  margin-top: 6px;
}
