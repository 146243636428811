.itembox.editing .innercontainer {
    background-color: #202020;
}

.itembox .formrow input {
    width: 100%;
    background-color: #444;
}

.itembox .formrow input.itemname {
    color: #ccc;
    font-family: "Libre Franklin","Avenir","Segoe UI","Seravek",apple-system,BlinkMacSystemFont,"Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    font-size: 11pt;
    font-weight: 400;
}

.itembox .formrow textarea {
    width: calc(100% - 2px);
    resize: none;
    background-color: #2a2a2a;
    color: #ccc;
    
    position: absolute;
    box-sizing: border-box;
    height: calc(100% - 4px);

    font-family: "Libre Franklin","Avenir","Segoe UI","Seravek",apple-system,BlinkMacSystemFont,"Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    font-size: 11pt;
    font-weight: 300;
    line-height: normal;

    border-color: #ddd;
}

.itembox .formrow textarea:focus {
    border-color: white;
    caret-color: white;
}

.itembox .formrow textarea.dirty:not(:focus), .itembox .formrow input.dirty:not(:focus) {
    background-color: #382020;
}

.itembox .shadowcontainer {
    overflow-y: hidden;
    position: relative;
    padding: 4px 2px 4px 2px;
}

.itembox .shadowtext {
    white-space: pre-line;
    visibility: hidden;
    font-family: "Libre Franklin","Avenir","Segoe UI","Seravek",apple-system,BlinkMacSystemFont,"Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    font-size: 11pt;
    font-weight: 300;
    line-height: normal;
    overflow-wrap: anywhere;
}

.itembox button.save {
    font-weight: bold;
    color: #9d9;
    border-color: #585;
}