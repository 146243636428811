.imagedialog {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    text-align: center;
    vertical-align: middle;
    background-color: rgba(30, 30, 30, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10500;
}
.imagedialog .image {
    width: 90vw;
    height: 90vh;
    position: absolute;
    font-family: 'Libre Franklin';
}

.imagedialog .image.editing {
    border: 2px dashed #f88;
}

.imagedialog .image img {
    position: absolute;
}

.imagedialog .image .itempins {
    position: absolute;
    
    & circle.clickable {
        cursor: pointer;
    }

    & text.clickable {
        cursor: pointer;
    }

    & text.shadow {
        text-shadow:0 0 6px black;
    }
}

.imagedialog .image.editing .itempins {
    cursor: crosshair;
}

.imagedialog .toolbar {
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    background-color: #444;
    width: 100vw;
    border-bottom: 1px solid #666;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    column-gap: 0.5em;
    padding-right: 0.5em;
    height: 30px;
}

.imagedialog .toolbar button {
    font-size: 16px;
    background-color: #ccc;
    color: #333;
}

.imagedialog .toolbar button.deleteall {
    color: #c00;
}

.imagedialog .toolbar div {
    display: flex;
    align-items: flex-end;
}

.imagedialog .addmarkerdialog {
    z-index: 10700;
    background-color: #333;
    padding: 2em;
    display: flex;
    flex-direction: column;
    text-align: left;
    row-gap: 0.3em;
}

.imagedialog .addmarkerdialog > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 0.5em;
}

.imagedialog .addmarkerdialog > div label {
    width: 50%;
}

.imagedialog .addmarkerdialog .addmarkerbuttons {
    justify-content: flex-end;
}

.imagedialog .addmarkerdialog input[type="checkbox"] {
    margin: 0px 0.5em 0px 0px;
}

.imagedialog .addmarkerdialog button {
    font-size: 16px;
    background-color: #ccc;
    color: #333;
}

.imagedialog .addmarkerdialog button:disabled {
    color: #aaa;
}