body {
  margin: 0;
  font-family: 'Source Sans 3', 'Seravek', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 12pt;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #181818;
  color: #ddd;
}

textarea, input, select {
  font-family: 'Source Sans 3', 'Seravek', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  background-color: #666;
  color: white;
  border-radius: 4px;
}

input::placeholder {
  color: #999;
}

textarea::placeholder {
  color: #999;
}

button {
  font-family: 'Source Sans 3', 'Seravek', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  border: 1px solid #ccc;
  background-color: #666;
  color: #ddd;
  border-radius: 4px;
  cursor: pointer;
}

button.disabled {
  border: 1px solid #888;
  color: #888;
}

button:hover:not(.disabled) {
  color: white;
  background-color: #888;
}

::-webkit-scrollbar-track {
  background: #333;
}

::-webkit-scrollbar-thumb {
  background: #666;
}

::-webkit-scrollbar {
  width: 5px;
}