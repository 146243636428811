@keyframes flash {
    0%, 50% {
        background-color: #555;
    }
    25%, 75% {
        background-color: #333;
    }
 }

.outerfocus.nofocusitem {
    margin: 2px;
    width:calc(min(300px, 100%));
    box-sizing: border-box;
}

.outerfocus.nofocusitem.expanded {
    width: calc(min(604px, 100%));
}

.outerfocus.focusitem {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(30, 30, 30, 0.9);
    z-index: 9000;
}

.outerfocus.focusitem .innerfocus {
    position: absolute;
    top: 10vh;
    left: calc((100vw - min(90vw, 50em)) / 2);
    width: calc(min(90vw, 50em));
    border:  solid #3338;
}

.itembox {
    display: flex;
    border: 1px solid #888;
    border-radius: 6px;
    background-color: #666;
    min-height: 200px;
    max-height: 400px;
}

.focusitem .itembox {
    min-height: unset;
    max-height: unset;
    height: 80vh;
}

.itembox.flash .innercontainer {
    animation-name: flash;
    animation-duration: 0.4s;
    animation-fill-mode: both;
}

.itembox.quest {
    background-color: #284;
}

.itembox.personororg {
    background-color: #d92;
}

.itembox.place {
    background-color: #b54;
}

.itembox.miscitem {
    background-color: #58a;
}

.itembox.logentry {
    background-color: #ccc;
}

.itembox .name {
    flex-grow: 1;
    font-weight: 500;
}

.itembox.archived .name {
    color: #888;
}

.itembox .innercontainer {
    flex-grow: 1;
    border: 1px solid #666;
    border-radius: 6px;
    margin: 2px;
    background-color: #333;
    padding: 0px 4px 4px 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    visibility: visible;
}

.itembox .formrow {
    display: flex;
    flex-direction: row;
    column-gap: 6px;
}

.itembox .grow {
    flex-grow: 1;
}

.itembox .formrow .grow {
    flex-grow: 1;
}

.itembox .formrow label {
    margin-right: 4px;
    margin-top: -2px;
}

.itembox .buttonrow {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.itembox .buttonrow .buttoncontainer {
    display: flex;
    gap: 4px;
}

.itembox .buttonrow.reverse {
    flex-direction: row-reverse;
}

.itembox .buttonrow button {
    height: 25px;
    border: 1px solid #888;
    background-color: #333;
    color: #ddd;
    cursor: pointer;
    border-radius: 2px;
}

.itembox .buttonrow button:hover:not(.disabled) {
    color: white;
    background-color: #888;
}

.itembox .buttonrow button.delete:hover {
    background-color: #844;
}

.itembox .type {
    color: #888;
    flex-grow: 1;
}

.itembox.archived .type {
    text-decoration: line-through;
}

.itembox .resize, .itembox .focus {
    text-align: right;
}

.itembox .resize svg, .itembox .focus svg {
    color: #aaa;
}

.itembox .resize svg:hover, .itembox .focus svg:hover {
    color: #eee;
    cursor: pointer;
}