.itembox .innercontainer .formrow.view {
    background-color: #2a2a2a;
    color: #ccc;
    padding: 4px 2px 4px 2px;
    overflow-y: auto;
    font-family: "Libre Franklin","Avenir","Segoe UI","Seravek",apple-system,BlinkMacSystemFont,"Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    font-size: 11pt;
    font-weight: 300;
    line-height: normal;
}

.itembox .innercontainer .formrow.view .link:hover {
    color: #fff;
}

.itembox .aliasbutton {
    width: 1em;
    text-align: center;
    background-color: #2a2a2a;
    border-radius: 4px;
    color: #aaa;
}

.itembox .aliasbutton:hover {
    color: #eee;
    background-color: #444;
    cursor: pointer;
}

.itembox a {
    color: #aaa;
    text-decoration-style: dotted;
    text-decoration-thickness: from-font;
    text-underline-offset: 2px;
    text-decoration-color: #888;
}

.itembox .image {
    width: 100%;
    text-align: center;
    background-color: #404040;
    position: relative;
}

.itembox .image .showcasebutton, .itembox .image .showhidebutton {
    display: none;
    position: absolute;
    margin: 4px;
    cursor: pointer;
    background-color: #333;
    color: #aaa;
    border: 1px solid #aaa;
    padding: 0px 2px;
}

.itembox .image .showhidebutton {
    left: 2em;
}

.itembox .image .showcasebutton:hover, .itembox .image .showhidebutton:hover {
    color: #eee;
}


.itembox .image:hover .showcasebutton, .itembox .image:hover .showhidebutton {
    display: block;
}

.itembox img {
    max-width: 250px;
    max-height: 100px;
    margin: 2px;
    cursor: pointer;
}

.itembox .image.hidden img {
    max-height: 20px;
}