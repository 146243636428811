.itembox ul {
    margin: 0px;
    padding: 0px;
}

.itembox .aliases ul li {
    list-style-type: none;
    cursor: pointer;
    padding-left: 0.5em;
    text-indent: -0.25em;
}

.itembox .aliases ul li .removeAlias {
    font-size: 9pt;
    margin-right: 0.5em;
    color: #666;
    filter: drop-shadow(0px 0px 1px black);
}

.itembox .aliases ul li .removeAlias:hover {
    color: #c66;
    filter: drop-shadow(0px 0px 1px red);
}

.itembox .aliasheading {
    color: #ccc;
}

.itembox input.newalias {
    color: #ddd;
    width: 0;
}